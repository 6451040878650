// React imports
import { useState, useContext, useEffect, use } from 'react';

// Custom imports
import SelectEmployeesView from '../../components/Widgets/WizardViews/SelectEmployeesView';
import EmployeeListWidget from '../../components/Widgets/EmployeeListWidget';
import Wizard from '../../components/Widgets/Wizard';
import AdminContext from 'AdminContext';
import QuestionModal from '../../components/Widgets/QuestionModal';
import FinancialYearDateView from '../../components/Widgets/WizardViews/FinancialYearDateView';
import FormControlLabel from "@mui/material/FormControlLabel";
import LoadingSpinner from '../../components/Widgets/LoadingSpinner';
import CheckBox from "@mui/material/Checkbox";
import EmailPaySummariesView from './EmailPaySummariesView';
import PaymentSummariesView from './PaymentSummariesView';
import { Card } from '@material-ui/core';
import { set } from 'date-fns';
import { fi } from 'date-fns/locale';

const EndOfYearView = () => {
  // Helpers
  const convertDateFormat = (dateString) => {
    // Converts "dd/mm/yyyy" format to "yyyy-mm-dd" format
    const [day, month, year] = dateString.split("/");
    return `${year}-${month}-${day}`;
  };

  // Constants
  const adminContext = useContext(AdminContext);
  const [emailPaymentSummaries, setEmailPaymentSummaries] = useState(false);
  const [loading, setLoading] = useState(false);
  const [nextEnabled, setNextEnabled] = useState(true);
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);
  const [buttonsVisible, setButtonsVisible] = useState(true);
  const [isSuccessOpen, setSuccessOpen] = useState(false);
  const [step, setStep] = useState(0);
  const [forceUpdateButtons, setForceUpdateButtons] = useState(false);
  const [adjustmentsMessage, setAdjustmentsMessage] = useState("");
  const [wizardInfo, setWizardInfo] = useState({
    multiple_pay_runs: true,
    pays: [],
    pdf_data: "",
    company: adminContext.company,
    employees: adminContext.company.employees,
    selected_employees: adminContext.company.employees,
    start_date: convertDateFormat(
      adminContext.appConfig.report_start_date.value
    ),
    end_date: convertDateFormat(adminContext.appConfig.report_end_date.value),
    selected_pays: [],
    show_terminated: false,
  }); 

  /* Functions */
  async function generatePaymentSummaries(
    summary_report = false,
    load_pdf = false,
    wizardInfoOverride = null
  ) {
    const info = wizardInfoOverride || wizardInfo;

    return new Promise((resolve, reject) => {
      adminContext.postRequest(
        `${adminContext.constants.BASE_URL}/end-of-year/payment-summaries/generate`,
        {
          ...info,
          summary_report,
          email: emailPaymentSummaries,
        },
        (response) => {
          try {
            const summaries = response.data.payment_summaries;
            const summaryEmployeeIds = new Set(
              summaries.map((s) => s.employee_id)
            );
            const filteredEmployees = adminContext.company.employees.filter(
              (emp) => summaryEmployeeIds.has(emp.id)
            );

            setWizardInfo((prev) => ({
              ...prev,
              payment_summaries: summaries,
              employees: filteredEmployees,
              selected_employees: filteredEmployees,
            }));

            if (filteredEmployees.length === 0) {
              setNextEnabled(false);
            }

            resolve(response);
          } catch (err) {
            reject(err);
          }
        },
        (error) => {
          setLoading(false);
          reject(error);
        }
      );
    });
  }
 
  const resetWizardEmployees = () => {
    const freshEmployees = adminContext.company.employees;

    const updated = {
      ...wizardInfo,
      employees: freshEmployees,
      selected_employees: freshEmployees,
      payment_summaries: [],
    };

    setWizardInfo(updated);
    return updated;
  };

  const handleSuccessClose = () => {
    setSuccessOpen(false);
    adminContext.history.goBack();
  };

  const handleNext = async () => {
    if (step === 0) {
      setLoading(true);
      const freshInfo = resetWizardEmployees();
      await generatePaymentSummaries(false, false, freshInfo);
      setLoading(false);
    }

    if (step === getLastStep()) {
      setConfirmationOpen(true);
    } else if (step < wizardSteps.length - 1) {
      setStep(step + 1);
    }
  };
 

  const handleBack = () => {
    if (step > 0) {
      setStep(step - 1);
      setNextEnabled(true);
    }
  };

  const isBackDisabled = () => {
    return step === 0;
  };

  const getLastStep = () => {
    return wizardSteps.length - 1;
  };

  const handleConfirmation = (response) => {
    if (response === "YES") {
      handleSuccessClose();
    }
    setConfirmationOpen(false);
  };

  // Wiarzrd steps
  const wizardSteps = [
    loading ? (
      <LoadingSpinner />
    ) : (
      <>
        <Card className="shadow-sm p-4">
          <h1 className="text-center days-one">End of Year Assistant</h1>
          <p>
            For your convenience, Lightning Payroll includes an End of Year
            section where you can print and email PAYG Payment Summaries (often
            still referred to as 'group certificates') to your employees. Since
            the introduction of Single Touch Payroll (STP), these summaries are
            no longer required, as employee earnings are reported directly to
            the ATO and are available in their MyGov income statements. However,
            some users still like to provide a payment summary, so we make it
            easy to do so if you choose.
          </p>
          <FinancialYearDateView
            setNextEnabled={setNextEnabled}
            wizardInfo={wizardInfo}
            setWizardInfo={setWizardInfo}
          />
          <FormControlLabel
            className="mt-3"
            label="Email payment summaries to employees?"
            control={
              <CheckBox
                checked={emailPaymentSummaries}
                color="primary"
                onChange={(e) => setEmailPaymentSummaries(e.target.checked)}
              />
            }
          />
        </Card>
      </>
    ),
    <>
      <Card className="shadow-sm p-4">
      <h1 className="text-center days-one mb-4">Select Employees</h1>
      {wizardInfo.employees.length === 0 && (
        <div className="alert alert-warning mt-3" role="alert">
          No employees with payment summaries were found in the selected
          financial year.
        </div>
      )}
      <EmployeeListWidget
        name="employees"
        rows={{ employees: wizardInfo.employees }}
        setRows={(newRows) => {
          setWizardInfo((prev) => ({
            ...prev,
            employees: newRows.employees,
          }));
          setForceUpdateButtons(true);
        }}
        selectedRows={{ employees: wizardInfo.selected_employees }}
        setSelectedRows={(newSelectedRows) => {
          setWizardInfo((prev) => ({
            ...prev,
            selected_employees: newSelectedRows.employees,
          }));
          setForceUpdateButtons(true);
        }}
        hideShowTerminated={true}
        defaultShowTerminated={true} 
      />
      </Card>
    </>,
    <PaymentSummariesView
      setNextEnabled={setNextEnabled}
      setForceUpdateButtons={setForceUpdateButtons}
      wizardInfo={wizardInfo}
      setWizardInfo={setWizardInfo}
      emailPaymentSummaries={emailPaymentSummaries}
      loading={loading}
      setLoading={setLoading}
    />,
    ...(emailPaymentSummaries
      ? [
          <EmailPaySummariesView
            setNextEnabled={setNextEnabled}
            setForceUpdateButtons={setForceUpdateButtons}
            wizardInfo={wizardInfo}
            setWizardInfo={setWizardInfo}
            emailPaymentSummaries={emailPaymentSummaries}
            loading={loading}
            setLoading={setLoading}
          />,
        ]
      : []),
  ];

  return (
    <>
      <Wizard
        wizardSteps={wizardSteps}
        handleNext={handleNext}
        handleBack={handleBack}
        cancelCallback={adminContext.history.goBack}
        nextEnabled={nextEnabled}
        step={step}
        setStep={setStep}
        buttonsVisible={buttonsVisible}
        forceUpdateButtons={forceUpdateButtons}
        setForceUpdateButtons={setForceUpdateButtons}
        isBackDisabled={isBackDisabled}
        getLastStep={getLastStep}
      />
      <QuestionModal
        isOpen={isConfirmationOpen}
        title="Confirm Quitting EOY Assistant"
        innerHTML="<p>You have finished the End of Year assistant. Please confirm that you wish to exit?</p>"
        onConfirm={() => handleConfirmation("YES")}
        onDeny={() => handleConfirmation("NO")}
      />
    </>
  ); 
};

export default EndOfYearView;