import { useState, useContext } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import FloatingSaveButton from "../../components/Widgets/SaveChangesMessageRow";
import AdminContext from "../../AdminContext";

const PayslipEmailOption = () => {
  const [sendCopy, setSendCopy] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const adminContext = useContext(AdminContext);

  const saveChanges = () => {
    adminContext.postRequest(
      adminContext.constants.BASE_URL +
      `/company/${adminContext.company.id}/update-email-config`,
      { cc_sender: adminContext.company.email_config.cc_sender, from_address: adminContext.company.email_config.from_address },
      (response) => {
        adminContext.setAlertMessage("Email settings updated successfully");
      }
    );
  }

  return (
    <Container className="m-3" fluid>
      <Card>
        <CardBody>
          <Row>
            <Col>
              <h1 className="days-one mt-5">Company Email Options</h1>
            </Col>
          </Row>
          <Row className='mx-2'><FormControlLabel
            control={
              <Checkbox
                checked={adminContext.company.email_config.cc_sender}
                onChange={(e) => adminContext.setCompany({
                  ...adminContext.company,
                  email_config: {
                    ...adminContext.company.email_config,
                    cc_sender: e.target.checked,
                  },
                })}
                name="cc_sender"
                color="primary"
              />
            }
            label="Send me a copy of any payslip emails?"
          /></Row>
          <Row className='mx-2'>
            <label className="text-muted mb-1 pb-0">Send copy to:</label>
            <TextField
              label="E.g. admin@mycompany.com.au"
              type="email"
              disabled={!adminContext.company.email_config.cc_sender}
              value={adminContext.company.email_config.from_address}
              onChange={(e) => adminContext.setCompany({
                ...adminContext.company,
                email_config: {
                  ...adminContext.company.email_config,
                  from_address: e.target.value,
                },
              })}
              variant="outlined"
              fullWidth
              className='my-1'
              margin="normal"
            /></Row ></CardBody></Card>
      <FloatingSaveButton
        errorMessage={errorMessage}
        successMessage={successMessage}
        saveChanges={saveChanges}
      />
    </Container>
  );
};

export default PayslipEmailOption;
