import { Alert, Button, Modal, ModalBody } from "reactstrap";
import React, { useState } from "react";

const FloatingSaveButton = ({
  saveChanges,
  disabledCallback,
  label = "Save Changes",
  icon = "fa-save",
  errorMessage,
  successMessage,
}) => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  return (
    <>
      {/* Floating Action Button */}
      <Button
        className="semi-active-button floating-save-btn d-flex flex-column align-items-center justify-contents-center"
        color="primary"
        type="submit"
        onClick={saveChanges !== undefined ? saveChanges : () => toggle()}
        disabled={disabledCallback !== undefined ? disabledCallback() : false}
      >
        <i className={`mx-auto  fa-regular fa-lg ${icon} mt-2 `} />
        <span className="mt-1">{label}</span>
      </Button>

      {/* Modal for Error Messages */}
      <Modal centered isOpen={modal} toggle={toggle} className="text-center">
        <ModalBody>
          <h2>Save Failed</h2>
          <p>Please check your form data for errors.</p>
          <Button color="info" onClick={toggle}>
            Close
          </Button>
        </ModalBody>
      </Modal>

      {/* Error & Success Messages */}
      {errorMessage || successMessage ? (
        <div className="floating-alerts">
          {errorMessage && (
            <Alert color="warning">
              <strong>{errorMessage}</strong>
            </Alert>
          )}
          {successMessage && (
            <Alert color="success">
              <strong>{successMessage}</strong>
            </Alert>
          )}
        </div>
      ) : null}
    </>
  );
};

export default FloatingSaveButton;
