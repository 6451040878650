import EditForm from "../EditForm";
import { useEffect, useContext } from "react";
import AdminContext from "../../../AdminContext";
import LoadingSpinner from "../LoadingSpinner";

const SelectPaysView = ({
  wizardInfo,
  setWizardInfo,
  getFormatNameForPay,
  setForceUpdateButtons,
  formSpec,
  rows,
  selectedRows,
  setRows,
  setSelectedRows,
  setOriginalPays,
  loading,
  setLoading,
  getFilteredPays,
  setNextEnabled,
  step,
}) => {
  const adminContext = useContext(AdminContext);

  // disable next if no selected rows. FIrst check that setnextenabled and selected rows is defined.
  useEffect(() => {
    if (
      setNextEnabled !== undefined &&
      selectedRows.pays !== undefined &&
      step !== undefined &&
      step === 3
    ) {
      setNextEnabled(selectedRows.pays.length > 0);
    }
  }, [selectedRows]);

  const initPays = () => {
    setLoading(true);
    // Payslips selection is very similar to direct entry selection so we can use the same endpoint.
    adminContext.postRequest(
      adminContext.constants.BASE_URL + "/pays/payslips/init",
      wizardInfo,
      (response) => {
        setLoading(false);
        if (response.data.pays_for_selection) {
          let pays = [];
          let count = 0;
          response.data.pays_for_selection.forEach((pay) => {
            if (count % 2 === 0) {
              pay.written_to_ABA = true;
            } else {
              pay.written_to_ABA = false;
            }
            count++;
            pays.push({
              id: pay.id,
              format_name: getFormatNameForPay(pay),
              written_to_ABA: pay.written_to_ABA,
            });
          });

          // Save the original pays.
          if (setOriginalPays !== undefined) {
            setOriginalPays(pays);
          }

          pays = getFilteredPays !== undefined ? getFilteredPays(pays) : pays;
          setRows({ pays: pays });
          setSelectedRows({ pays: pays });
        }
      }
    );
  };

  useEffect(() => {
    setForceUpdateButtons(true);
  }, [rows]);

  useEffect(() => {
    if (step !== 2 || !setNextEnabled) return; // Exit early if not on step 2 or if setNextEnabled is unavailable

    if (wizardInfo.debit_lodgement_reference === undefined) {
      return; // Do nothing if debit_lodgement_reference is undefined
    }

    if (!wizardInfo.debit_lodgement_reference) {
      console.log("Setting next enabled to false");
      setNextEnabled(false);
    } else {
      setNextEnabled(true);
    }
  }, [wizardInfo.debit_lodgement_reference, step, setNextEnabled]);

  useEffect(() => {
    if (wizardInfo.multiple_pay_runs) {
      initPays();
    } else {
      let pays = wizardInfo.pays;
      setRows({
        pays: getFilteredPays !== undefined ? getFilteredPays(pays) : pays,
      });
      setSelectedRows({
        pays: getFilteredPays !== undefined ? getFilteredPays(pays) : pays,
      });
      if (setOriginalPays !== undefined) {
        setOriginalPays(pays);
      }
    }
  }, []);

  return (
    <div>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div>
          <EditForm
            liveEdit={true}
            formSpec={formSpec}
            setModelObj={setWizardInfo}
            modelObj={wizardInfo}
          />
        </div>
      )}
    </div>
  );
};

export default SelectPaysView;
