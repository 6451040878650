// React imports
import { React, useEffect, useState, useContext, use } from 'react';
import Modal from "react-modal";
import { Card, Table, CardBody, Label, Button, Row, Col } from 'reactstrap';
import { FaWindowClose } from "react-icons/fa";

// Custom imports
import TableWidget from '../../components/Widgets/TableWidget';
import AdminContext from 'AdminContext';
import LoadingSpinner from '../../components/Widgets/LoadingSpinner';

const PaymentSummariesView = ({
  wizardInfo,
  emailPaymentSummaries,
  setWizardInfo,
  setForceUpdateButtons,
}) => {
  // Constants
  const adminContext = useContext(AdminContext);
  const [employees, setEmployees] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [loading, setLoading] = useState(false);
  const [payment_summaries, setPaymentSummaries] = useState([]);
  const customStyle = {
    overlay: { zIndex: 1000 },
  };
  const [pdfB64Data, setPdfB64Data] = useState("");
  const [reactPdfDocument, setReactPdfDocument] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(true);

  /* Functions */
  function openModal() {
    setModalIsOpen(true);
  }
  function closeModal() {
    setModalIsOpen(false);
  }

  // load_pdf flag as on initial load we only want data but no pdf
  const generatePaymentSummaries = (summary_report = false, load_pdf = true) => {
    setLoading(true);
    adminContext.postRequest(
      adminContext.constants.BASE_URL + `/end-of-year/payment-summaries/generate`,
      {
        ...wizardInfo,
        summary_report: summary_report,
        email: emailPaymentSummaries,
      },
      (response) => {
        if (load_pdf) {
          setPdfB64Data(
            "data:application/pdf;base64," + response.data.pdf_file_data
          );
          setReactPdfDocument(
            <div id="report-preview">
              <iframe
                width="100%"
                height="100%"
                src={
                  "data:application/pdf;base64," + response.data.pdf_file_data
                }
              />
            </div>
          );
          openModal();
        }
        const summaries = response.data.payment_summaries;
        setPaymentSummaries(summaries);

        // Filter out employees that don't have a payment summary 
        const summaryEmployeeIds = new Set(summaries.map((s) => s.employee_id));
        setEmployees((prev) =>
          prev.filter((emp) => summaryEmployeeIds.has(emp.id))
        );

        setLoading(false);
      },
      (error) => {
        setLoading(false);
      }
    );
  };

  /* Use Effects */
  useEffect(() => {
    setEmployees(wizardInfo.selected_employees);
    generatePaymentSummaries(false, false);
    // If there is atlest one employee selected, then set them as selected for payment summary
    if (wizardInfo.selected_employees.length > 0) {
      setSelectedEmployees(wizardInfo.selected_employees[0].id);
    }
  }, []);

  // Table fields
  const columns = [{ accessor: "format_name", label: "Employee" }];

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Row>
            {/* Left side: Employee Table */}
            <Col lg={6} xs={12}>
              <Card className="mb-3 mb-lg-0">
                <CardBody>
                  <h1 className="text-center days-one">Payment Summaries</h1>
                  <TableWidget
                    rows={employees}
                    columns={columns}
                    selectsDisplayed={true}
                    isEndOfYearTable={true}
                    multiSelectsEnabled={false}
                    selected={selectedEmployees}
                    setSelected={setSelectedEmployees}
                    title="Employees"
                  />
                  <Button
                    color="primary"
                    className="btn btn-primary btn-lg mt-3 mt-lg-0"
                    onClick={() => generatePaymentSummaries(false)}
                    disabled={payment_summaries.length === 0 || employees.length === 0}
                  >
                    Payment Summaries
                  </Button>
                  <Button 
                    color="primary"
                    className="btn btn-primary btn-lg mt-3 mt-lg-0"
                    onClick={() => generatePaymentSummaries(true)}
                    disabled={payment_summaries.length === 0 || employees.length === 0}
                  >
                    Summary Report
                  </Button>
                </CardBody>
              </Card>
            </Col>

            {/* Right side: Summary Display */}
            {selectedEmployees && payment_summaries.length > 0 && (
              <Col lg={6} xs={12}>
                <Card>
                  <CardBody>
                    <h1 className="text-center days-one mb-4">Summary Display</h1>
                    {payment_summaries
                      .filter((summary) => summary.employee_id === selectedEmployees)
                      .map((summary) => (
                        <div key={summary.id}>
                          <h2 className="text-left">{summary.employee_name}</h2>
                          <Row className="mb-2">
                            <Col md={6}>
                              <Label><strong>Start Date:</strong> {summary.start_date}</Label>
                            </Col>
                            <Col md={6}>
                              <Label><strong>End Date:</strong> {summary.end_date}</Label>
                            </Col>
                          </Row>

                          <hr className="my-3" />

                          <Row>
                            <Col md={6}>
                              <Label><strong>Tax:</strong> ${summary.tax.toFixed(2)}</Label><br />
                              <Label><strong>Gross:</strong> ${summary.gross.toFixed(2)}</Label><br />
                              <Label><strong>Total Itemised Allowances:</strong> $0.00</Label><br />
                              <Label><strong>Total Deductions:</strong> $0.00</Label><br />
                              <Label><strong>Reportable Fringe Benefits:</strong> ${summary.reportable_fringe_benefits.toFixed(2)}</Label><br />
                              <Label><strong>CDEP:</strong> ${summary.cdep.toFixed(2)}</Label><br />
                              <Label><strong>RESC:</strong> ${summary.resc.toFixed(2)}</Label><br />
                              <Label><strong>Workplace Giving:</strong> ${summary.workplace_giving.toFixed(2)}</Label><br />
                            </Col>
                            <Col md={6}>
                              <Label><strong>Amendment?</strong> <input type="checkbox" checked={summary.is_amendment} disabled /></Label><br />
                              <Label><strong>Is FBT Exempt?</strong> <input type="checkbox" checked={summary.is_fbt_exempt} disabled /></Label><br />
                              <Label><strong>Lump Sum A:</strong> ${summary.lump_sum_a.toFixed(2)}</Label><br />
                              <Label><strong>Lump Sum A Type:</strong> {summary.lump_sum_a_type}</Label><br />
                              <Label><strong>Lump Sum B:</strong> ${summary.lump_sum_b.toFixed(2)}</Label><br />
                              <Label><strong>Lump Sum D:</strong> ${summary.lump_sum_d.toFixed(2)}</Label><br />
                              <Label><strong>Lump Sum E:</strong> ${summary.lump_sum_e.toFixed(2)}</Label><br />
                              <Label><strong>Union Fees:</strong> ${summary.union_fees.toFixed(2)}</Label><br />
                            </Col>
                          </Row>
                        </div>
                      ))}
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
 
          {/* PDF Modal */}
          <Row>
            <Col>
              {pdfB64Data && !adminContext.isNative && (
                <div>
                  <Modal
                    style={customStyle}
                    isOpen={modalIsOpen}
                    contentLabel="Lightning Payroll Report"
                  >
                    <Button
                      color="secondary"
                      onClick={closeModal}
                      className="float-right mb-2"
                    >
                      <FaWindowClose className="mr-3 mb-1" />
                      Close
                    </Button>
                    {reactPdfDocument}
                  </Modal>
                </div>
              )}
            </Col>
            <Col></Col>
          </Row>
        </>
      )}
    </>
  );
};

export default PaymentSummariesView;