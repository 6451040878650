import React, { useEffect } from "react";
import AdminContext from "../../AdminContext";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { FormControl } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "reactstrap";

function ComboBox(props) {
  const adminContext = React.useContext(AdminContext);
  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const tooltipCloseTimeout = React.useRef(null);

  const showTooltip = () => {
    clearTimeout(tooltipCloseTimeout.current);
    setTooltipOpen(true);
  };

  const hideTooltip = () => {
    tooltipCloseTimeout.current = setTimeout(() => {
      setTooltipOpen(false);
    }, 3000); // Delay before the tooltip disappears
  };

  const changeValue = (e) => {
    props.setSelectedComboItem(e.currentTarget.textContent);
  };

  const sortedKeys = () => {
    const sortFunction = (a, b) => {
      // Prioritize the 'All' key to be the first item
      if (a === "All") return -1;
      if (b === "All") return 1;

      let aValue = props.comboData[a];
      let bValue = props.comboData[b];

      if (aValue < bValue) {
        return -1;
      }
      if (aValue > bValue) {
        return 1;
      }
      return 0;
    };

    if (props.sortByKey) {
      return Object.keys(props.comboData).sort();
    }

    if (!props.sortCallback && !props.skipSort) {
      return Object.keys(props.comboData).sort(sortFunction);
    }

    if (props.skipSort) {
      return Object.keys(props.comboData);
    }

    return Object.keys(props.comboData).sort(props.sortCallback);
  };

  const tooltipId = `tooltip_${props.label}`;

  return (
    <FormControl fullWidth>
      {props.label && (
        <>
          <label className="form-label">
            {props.label}
            {props.infoText && (
              <>
                {" "}
                <FontAwesomeIcon
                  id={tooltipId}
                  icon={faInfoCircle}
                  className="text-primary"
                  style={{ cursor: "pointer" }}
                  onMouseEnter={showTooltip}
                  onMouseLeave={hideTooltip}
                />
                <Tooltip
                  placement="right"
                  isOpen={tooltipOpen}
                  target={tooltipId}
                  toggle={hideTooltip}
                  onMouseEnter={showTooltip}
                  onMouseLeave={hideTooltip}
                >
                  {props.infoText}
                </Tooltip>
              </>
            )}
          </label>
        </>
      )}
      <Select
        disabled={props.disabled ? props.disabled : false}
        className={
          props.stretch
            ? "mb-3 width-100 bg-white pr-4" + props.className
            : props.className + " bg-white pr-4"
        }
        label={props.label}
        value={
          sortedKeys().includes(props.selectedComboItem)
            ? props.selectedComboItem
            : sortedKeys().includes(String(props.selectedComboItem))
            ? String(props.selectedComboItem)
            : ""
        }
      >
        {props.comboData
          ? sortedKeys().map((key) => (
              <MenuItem value={key} onClick={changeValue} id={key} key={key}>
                {props.titleCase
                  ? adminContext.toTitleCase(props.comboData[key])
                  : props.comboData[key]}
              </MenuItem>
            ))
          : null}
      </Select>
    </FormControl>
  );
}
export default ComboBox;
