import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import AdminLayout from "./layouts/Admin.js";
import AuthLayout from "./layouts/Auth.js";

import ReactDOM from "react-dom";
import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import "assets/css/custom.min.css";
import "assets/css/fontawesome.css";
import "assets/css/regular.css";
import "assets/css/solid.css";
import "assets/css/brands.css";
import "react-datepicker/dist/react-datepicker.css";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { Capacitor } from "@capacitor/core";
import { overrideConsoleMethods } from "errorBoundaries/consoleOverride";

// Function to fetch and set configuration
function fetchConfigAndRenderApp() {
  // Determine whether the app is running on a native platform
  const isNativePlatform = Capacitor.isNativePlatform();

  if (isNativePlatform) {
    // Override console methods to capture logs
    overrideConsoleMethods();
  }

  // Choose the appropriate config file based on the platform
  const configFile = isNativePlatform ? "/native_config.json" : "/config.json";

  fetch(configFile)
    .then((response) => response.json())
    .then((config) => {
      // Set global variables or pass them to components as needed
      window.REACT_APP_BASE_URL = config.REACT_APP_BASE_URL;
      window.REACT_APP_HOMEPAGE_URL = config.REACT_APP_HOMEPAGE_URL;

      // Render the app after config is loaded
      ReactDOM.render(
        <BrowserRouter>
          <Switch>
            <Route path="/admin" component={AdminLayout} />
            <Route path="/auth" component={AuthLayout} />
            <Redirect from="/" to="/admin" />
          </Switch>
        </BrowserRouter>,
        document.getElementById("root")
      );
    })
    .catch((error) => {
      console.error("Failed to load configuration:", error);
    });
}

// Call the function to start the app
fetchConfigAndRenderApp();
