import { Container, Row, Col, Card, CardBody, Button } from "reactstrap";
import TableWidget from "../../components/Widgets/TableWidget";
import { useEffect, useState, useContext } from "react";
import LoadingSpinner from "../../components/Widgets/LoadingSpinner";
import AdminContext from "../../AdminContext";

const TimeclockDevices = () => {
  const adminContext = useContext(AdminContext);
  const [isLoading, setIsLoading] = useState(true);

  const initView = async () => {
    setIsLoading(true);
    // Get initial data
    adminContext.getRequest(
      adminContext.constants.BASE_URL +
        `/company/${adminContext.company.id}/timeclock-devices`,
      (response) => {
        if (response.data.company_timeclock_devices) {
          adminContext.setCompany({
            ...adminContext.company,
            company_timeclock_devices: response.data.company_timeclock_devices,
          });
        }
        setIsLoading(false);
      }
    );
  };

  useEffect(() => {
    initView();
  }, []);

  const updateStatus = (row) => {
    setIsLoading(true);
    adminContext.putRequest(
      adminContext.constants.BASE_URL +
        `/company/${adminContext.company.id}/timeclock-devices/${row.id}`,
      { status: row.status },
      (response) => {
        setIsLoading(false);
        if (response.data.message) {
          adminContext.setAlertColor("success");
          adminContext.setAlertMessage(response.data.message);
          initView();
        } else if (response.data.error) {
          adminContext.setAlertColor("warning");
          adminContext.setAlertMessage(response.data.error);
        }
      }
    );
  };

  return (
    <fieldset disabled={!adminContext.editAccess("company")}>
      <Container className="mt-4" fluid>
        <Card className="bg-secondary shadow">
          <CardBody>
            {!isLoading ? (
              <>
                <Row>
                  <Col>
                    <h1 className="days-one">Timeclock Devices</h1>
                  </Col>
                </Row>
                <Row>
                  <Col className="mx-4 my-3">
                    <Row>
                      <Col>
                        <div key={adminContext.company.id}>
                          <label name="classification">
                            Below are the mobile timeclock devices connected to
                            your Lightning Payroll. These are added by logging
                            into to your account through the Android & iOS
                            Lightning Payroll Timeclock Apps.
                          </label>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col className="mx-4 my-3">
                    <Row>
                      <Col>
                        <Button onClick={initView}>Check for Devices</Button>
                        <TableWidget
                          rows={
                            adminContext.company.company_timeclock_devices || []
                          }
                          setRows={(newRows) => {
                            adminContext.setCompany({
                              ...adminContext.company,
                              company_timeclock_devices: newRows,
                            });
                          }}
                          columns={[
                            {
                              label: "Device ID",
                              accessor: "device_id",
                            },
                            {
                              label: "Android Version",
                              accessor: "android_version",
                            },
                            {
                              label: "Device Model",
                              accessor: "device_model",
                            },
                            {
                              label: "Nickname",
                              accessor: "user",
                              widget: "TextInput",
                            },
                            {
                              label: "Status",
                              accessor: "status",
                              widget: "ComboBox",
                              args: {
                                comboDataCallback: () => {
                                  return {
                                    Active: "Active",
                                    Inactive: "Inactive",
                                  };
                                },
                                setRowCallback: updateStatus,
                              },
                            },
                            {
                              label: "Send Updated Employee Data",
                              accessor: "refresh",
                              widget: "Button",
                              args: {
                                buttonIcon: "fas fa-sync",
                                onClickCallback: updateStatus,
                              },
                            },
                          ]}
                          formSpec={{
                            title: "Timeclock Devices",
                            fields: [
                              {
                                label: "Device ID",
                                accessor: "device_id",
                              },
                              {
                                label: "Android Version",
                                accessor: "android_version",
                              },
                              {
                                label: "Device Model",
                                accessor: "device_model",
                              },
                              {
                                label: "Nickname",
                                accessor: "user",
                              },
                              {
                                label: "Status",
                                accessor: "status",
                                widget: "ComboBox",
                              },
                            ],
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            ) : (
              <LoadingSpinner />
            )}
          </CardBody>
        </Card>
      </Container>
    </fieldset>
  );
};
export default TimeclockDevices;
