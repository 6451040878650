// React imports
import { useState, useEffect, useContext, use } from "react";
import { Card, CardBody, Button } from "reactstrap";

// Custom imports
import TableWidget from "../../components/Widgets/TableWidget";
import LoadingSpinner from "../../components/Widgets/LoadingSpinner";
import AdminContext from "../../AdminContext";
import SaveChangesMessageRow from "../../components/Widgets/SaveChangesMessageRow";
import { set } from "date-fns";

const EmailPaySummariesView = ({
  wizardInfo,
  setWizardInfo,
  setNextEnabled,
  setForceUpdateButtons,
  loading,
  setLoading,
}) => {
  // Constants
  const adminContext = useContext(AdminContext);
  const [employees, setEmployees] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  /* Functions */
  const sendEmails = () => {
    setLoading(true);
    adminContext.postRequest(
      adminContext.constants.BASE_URL + `/end-of-year/payment-summaries/generate`,
      {
        ...wizardInfo,
        selected_employees_to_email: selectedEmployees,
        email: true,
      },
      (response) => {
        setLoading(false);
        setNextEnabled(true);

        if (response.status === 200) {
          setErrorMessage("");
          setSuccessMessage("Emails sent successfully.");
        } else {
          setErrorMessage("Something went wrong. Unable to send emails.");
          setSuccessMessage("");
        }
        setTimeout(() => {
          setErrorMessage("");
          setSuccessMessage("");
        }, 5000);
      }
    );
  };

  /* Use Effects */
  useEffect(() => {
    const activeEmployees = wizardInfo.selected_employees.filter(
      (emp) => emp.email_payment_summary
    );

    setEmployees(activeEmployees);

    if (activeEmployees.length === 0) {
      setNextEnabled(true);
    } else {
      setNextEnabled(false);
    }
  }, []);

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Card className="shadow-sm">
          <CardBody className="text-center">
            <h1 className="text-center days-one">Email Pay Summaries</h1>

            {/* Employees to Email in Table */}
            <TableWidget
              title="Employees to Email"
              rows={employees}
              columns={[
                {
                  label: "Employee",
                  accessor: "format_name",
                },
                {
                  label: "Email",
                  accessor: "email_address",
                }
              ]}
              selected={selectedEmployees}
              setSelected={setSelectedEmployees}
              selectsDisplayed={true}
              multiSelectsEnabled={true}
            />

            {/* Send Emails Button */}
            <SaveChangesMessageRow
              errorMessage={errorMessage}
              successMessage={successMessage}
              saveChanges={sendEmails}
              label="Send Emails Now"
              icon="fa-envelope"
            /> 
          </CardBody>
        </Card>
      )}
    </>
  );
};

export default EmailPaySummariesView;