import { Row, Col, Card, CardBody, Container } from "reactstrap";
import { useContext, useState, useEffect } from "react";
import AdminContext from "../../../AdminContext";
import LoadingSpinner from "../../../components/Widgets/LoadingSpinner";
import ComboBox from "../../../components/Widgets/ComboBox";
import MoneyLabel from "../../../components/Widgets/MoneyLabel";
import TableWidget from "../../../components/Widgets/TableWidget";

function YearToDateTab() {
  const adminContext = useContext(AdminContext);
  const [isLoading, setIsLoading] = useState(false);
  const [ytdSummaries, setYtdSummaries] = useState({});
  const [leaveRows, setLeaveRows] = useState({});
  const [allowanceRows, setAllowanceRows] = useState({});
  const [deductionRows, setDeductionRows] = useState({});
  const [saving, setSaving] = useState(false);

  const [comboData, setComboData] = useState({
    financial_years: {},
    income_stream: {},
  });

  const [selectedComboItem, setSelectedComboItem] = useState({
    financial_year_id: 0,
    income_stream: "",
  });

  const initView = async () => {
    setIsLoading(true);
    let fyID = selectedComboItem.financial_year_id;
    let url =
      adminContext.constants.BASE_URL +
      `/employees/${adminContext.currentEmployee.id}/ytd`;
    if (fyID) {
      url = url + "?financial_year_id=" + fyID;
    }

    // Get initial data
    adminContext.getRequest(url, (response) => {
      if (
        response.data.financial_year_dict &&
        response.data.income_streams_dict
      ) {

        setComboData({
          financial_years: response.data.financial_year_dict,
          income_stream: response.data.income_streams_dict,
        });
      }
      if (response.data.income_stream && response.data.financial_year) {
        setSelectedComboItem({
          income_stream: response.data.income_stream,
          financial_year_id: response.data.financial_year.id,
        });
      } else if (response.data.income_stream) {
        setSelectedComboItem({
          ...selectedComboItem,
          income_stream: response.data.income_stream,
        });
      } else if (response.data.financial_year) {
        setSelectedComboItem({
          ...selectedComboItem,
          financial_year_id: response.data.financial_year.id,
        });
      }
      if (response.data.ytd_summaries) {
        setYtdSummaries(response.data.ytd_summaries);
      }
      if (response.data.leave_rows) {
        setLeaveRows(response.data.leave_rows);
      }
      if (response.data.allowance_rows) {
        setAllowanceRows(response.data.allowance_rows);
      }

      if (response.data.deduction_rows) {
        setDeductionRows(response.data.deduction_rows);
      }
      setIsLoading(false);
    });
  };

  useEffect(() => {
    initView();
  }, [adminContext.currentEmployee.id]);

  useEffect(() => {
    if (saving) {
      setSaving(false);
      initView();
    }
  }, [selectedComboItem]);

  const getKeyByValue = (object, value) => {
    return Object.keys(object).find((key) => object[key] === value);
  };

  return (
    <Container className="mt-4" fluid>
      <Card className="bg-secondary shadow">
        <CardBody>
          {!isLoading ? (
            <>
              <Row>
                <Col>
                  <h1 className="days-one">Year To Date Summary</h1>
                </Col>
              </Row>
              <Row>
                <Col md="4" id="ytd-lhs">
                  <Row className="my-2">
                    <Col>
                      <ComboBox
                        label="Financial Year"
                        name="financial_year_id"
                        sortCallback={(a, b) => {
                          let aValue = comboData.financial_years[a];
                          let bValue = comboData.financial_years[b];
                          if (aValue > bValue) {
                            return -1;
                          }
                          if (aValue < bValue) {
                            return 1;
                          }
                          return 0;
                        }}
                        comboData={comboData.financial_years}
                        selectedComboItem={selectedComboItem.financial_year_id}
                        setSelectedComboItem={(item) => {
                          setSaving(true);
                          setSelectedComboItem({
                            ...selectedComboItem,
                            financial_year_id: getKeyByValue(
                              comboData.financial_years,
                              item
                            ),
                          });
                        }}
                      />
                    </Col>
                  </Row>
                  <Row className="my-2">
                    <Col>
                      <ComboBox
                        label="Income Stream"
                        name="income_stream"
                        comboData={comboData.income_stream}
                        selectedComboItem={selectedComboItem.income_stream}
                        setSelectedComboItem={(item) => {
                          setSelectedComboItem({
                            ...selectedComboItem,
                            income_stream: getKeyByValue(
                              comboData.income_stream,
                              item
                            ),
                          });
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="8">
                      <strong>Gross</strong>
                    </Col>
                    <Col className="text-right">
                      <MoneyLabel
                        amount={
                          ytdSummaries[selectedComboItem.income_stream]
                            ? ytdSummaries[selectedComboItem.income_stream]
                                .gross.amount
                            : { _decimal: 0 }
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="8">Ordinary Time Earnings</Col>
                    <Col className="text-right">
                      <MoneyLabel
                        amount={
                          ytdSummaries[selectedComboItem.income_stream]
                            ? ytdSummaries[selectedComboItem.income_stream]
                                .ordinary_time_earnings.amount
                            : { _decimal: 0 }
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="8">Overtime Earnings</Col>
                    <Col className="text-right">
                      <MoneyLabel
                        amount={
                          ytdSummaries[selectedComboItem.income_stream]
                            ? ytdSummaries[selectedComboItem.income_stream]
                                .overtime_earnings.amount
                            : { _decimal: 0 }
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="8">Directors Fees</Col>
                    <Col className="text-right">
                      <MoneyLabel
                        amount={
                          ytdSummaries[selectedComboItem.income_stream]
                            ? ytdSummaries[selectedComboItem.income_stream]
                                .total_directors_fees
                            : { _decimal: 0 }
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="8">Other Bonuses/Commissions</Col>
                    <Col className="text-right">
                      <MoneyLabel
                        amount={
                          ytdSummaries[selectedComboItem.income_stream]
                            ? ytdSummaries[selectedComboItem.income_stream]
                                .total_non_directors_fees_bonus_and_commission
                            : { _decimal: 0 }
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="8"> &nbsp;</Col>
                  </Row>
                  <Row>
                    <Col md="8">
                      <strong>Tax</strong>
                    </Col>
                    <Col className="text-right">
                      <MoneyLabel
                        amount={
                          ytdSummaries[selectedComboItem.income_stream]
                            ? ytdSummaries[selectedComboItem.income_stream].tax
                                .amount
                            : { _decimal: 0 }
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="8">Help/TSL Component</Col>
                    <Col className="text-right">
                      <MoneyLabel
                        amount={
                          ytdSummaries[selectedComboItem.income_stream]
                            ? ytdSummaries[selectedComboItem.income_stream].help
                                .amount
                            : { _decimal: 0 }
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="8">SFSS Component</Col>
                    <Col className="text-right">
                      <MoneyLabel
                        amount={
                          ytdSummaries[selectedComboItem.income_stream]
                            ? ytdSummaries[selectedComboItem.income_stream].sfss
                                .amount
                            : { _decimal: 0 }
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="8">CDEP Component</Col>
                    <Col className="text-right">
                      <MoneyLabel
                        amount={
                          ytdSummaries[selectedComboItem.income_stream]
                            ? ytdSummaries[selectedComboItem.income_stream].cdep
                                .amount
                            : { _decimal: 0 }
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="8"> &nbsp;</Col>
                  </Row>
                  <Row>
                    <Col md="8">Net</Col>
                    <Col className="text-right">
                      <MoneyLabel
                        amount={
                          ytdSummaries[selectedComboItem.income_stream]
                            ? ytdSummaries[selectedComboItem.income_stream].net
                                .amount
                            : { _decimal: 0 }
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="8">Super Guarantee</Col>
                    <Col className="text-right">
                      <MoneyLabel
                        amount={
                          ytdSummaries[selectedComboItem.income_stream]
                            ? ytdSummaries[selectedComboItem.income_stream]
                                .super_levy.amount
                            : { _decimal: 0 }
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="8">RESC</Col>
                    <Col className="text-right">
                      <MoneyLabel
                        amount={
                          ytdSummaries[selectedComboItem.income_stream]
                            ? ytdSummaries[selectedComboItem.income_stream].resc
                                .amount
                            : { _decimal: 0 }
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="8">Tax Exempt Foreign Income</Col>
                    <Col className="text-right">
                      <MoneyLabel
                        amount={
                          ytdSummaries[selectedComboItem.income_stream]
                            ? ytdSummaries[selectedComboItem.income_stream]
                                .exempt_foreign_income.amount
                            : { _decimal: 0 }
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <br />
                      <p>
                        Note: The above gross amount includes itemised
                        allowances and excludes reportable employer
                        superannuation contributions (RESC)
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col md="8" id="ytd-rhs">
                  <Row>
                    <label className="mb--2">
                      <strong>Allowances</strong>
                    </label>
                    <TableWidget
                      rows={
                        allowanceRows[selectedComboItem.income_stream] !==
                        undefined
                          ? allowanceRows[selectedComboItem.income_stream]
                          : []
                      }
                      columns={[
                        {
                          label: "Description",
                          accessor: "description",
                          widget: "Text",
                        },
                        {
                          label: "Taxable?",
                          accessor: "is_taxable",
                          widget: "BooleanLabel",
                        },
                        {
                          label: "Itemised?",
                          accessor: "is_itemised",
                          widget: "BooleanLabel",
                        },
                        {
                          label: "Allowance Category",
                          accessor: "allowance_category",
                          widget: "Text",
                          valueCallback: (allowance) => {
                            return adminContext.constants
                              .STPAllowanceCategories[
                              allowance.allowance_category
                            ];
                          },
                        },
                        {
                          label: "Amount",
                          align: "right",

                          accessor: "amount",
                          widget: "MoneyLabel",
                        },
                      ]}
                    />
                  </Row>
                  <Row>
                    <label className="mb--2">
                      <strong>Deductions</strong>
                    </label>
                    <TableWidget
                      rows={
                        deductionRows[selectedComboItem.income_stream] !==
                        undefined
                          ? deductionRows[selectedComboItem.income_stream]
                          : []
                      }
                      columns={[
                        {
                          label: "Description",
                          accessor: "description",
                          widget: "Text",
                        },
                        {
                          label: "Classification",
                          accessor: "classification",
                          widget: "Text",
                          valueCallback: (deduction) => {
                            return adminContext.constants.DEDUCTION_TYPES[
                              deduction.classification
                            ];
                          },
                        },
                        {
                          label: "Amount",
                          accessor: "amount",
                          align: "right",
                          widget: "MoneyLabel",
                        },
                      ]}
                    />
                  </Row>
                  <Row>
                    <label className="mb--2">
                      <strong>Leave</strong>
                    </label>
                    <TableWidget
                      rows={
                        leaveRows[selectedComboItem.income_stream] !== undefined
                          ? leaveRows[selectedComboItem.income_stream]
                          : []
                      }
                      columns={[
                        {
                          label: "Leave Name",
                          accessor: "leave_name",
                          widget: "Text",
                        },
                        {
                          label: "Amount",
                          accessor: "amount",
                          align: "right",
                          widget: "MoneyLabel",
                        },
                      ]}
                    />
                  </Row>
                </Col>
              </Row>
            </>
          ) : (
            <LoadingSpinner />
          )}
        </CardBody>
      </Card>
    </Container>
  );
}
export default YearToDateTab;
