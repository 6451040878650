import { Container, Row, Col, Button } from "reactstrap";
import { useEffect, useState, useContext } from "react";
import LoadingSpinner from "../../components/Widgets/LoadingSpinner";
import ComboBox from "../../components/Widgets/ComboBox";
import AdminContext from "../../AdminContext";
import { Card } from "@material-ui/core";
import DateRangeEdit from "../../components/Widgets/DateRangeEdit";
import CheckBox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import ListWidget from "../../components/Widgets/ListWidget";
import Modal from "react-modal";
import FontPicker from "../../components/Widgets/FontPicker";

import { FaWindowClose } from "react-icons/fa";
import {
  updateEmployeeLists,
  processReport,
  initReport,
} from "./CustomReportsSharedFunctions";

// Set the app element to ensure screen readers handle it properly
if (process.env.NODE_ENV !== "test") {
  Modal.setAppElement("#root");
};

// LaunchCustomReports component
function LaunchCustomReports() {
  const adminContext = useContext(AdminContext);
  const [form, setForm] = useState({
    selected_report_id: 0,
    use_processed_date: true,
    use_processed_date_visible: false,
    show_terminated: true,
    show_only_totals: false,
    show_only_totals_visible: false,
    group_by_department: false,
    group_by_department_visible: false,
    start_date: new Date(),
    end_date: new Date(),
    employees: [],
    existing_reports: {},
    report_types: {},
    report_unsaved: true,
    report_name: "Untitled Report",
    fonts: [],
    font_family: "Arial",
    font_size: 7,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [rows, setRows] = useState({
    employees: adminContext.company.employees,
  });
  const [selectedRows, setSelectedRows] = useState({
    employees: [],
  });

  const [reactPdfDocument, setReactPdfDocument] = useState(null);
  const customStyle = {
    overlay: { zIndex: 1000 },
  };
  const [pdfB64Data, setPdfB64Data] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(true);

  function openModal() {
    setModalIsOpen(true);
  }
  function closeModal() {
    setModalIsOpen(false);
  }

  // Initial load of the report data
  useEffect(() => {
    initReport(
      adminContext,
      form,
      setForm,
      null,
      setIsLoading,
      rows,
      setRows,
      setSelectedRows,
      true
    );
  }, []);

  useEffect(() => {
    updateEmployeeLists(
      rows,
      setRows,
      adminContext,
      form,
      selectedRows,
      setSelectedRows
    );
  }, [form.show_terminated]); // eslint-disable-line react-hooks/exhaustive-deps
  if (
    form &&
    (!form.existing_reports || Object.keys(form.existing_reports).length === 0)
  ) {
    return (
      <Container className="mt-4" fluid>
        <Card className="p-3 m-3">
          <Row className="mx-3">
            <Col>
              <h1 className="days-one">Launch Custom Reports</h1>
            </Col>
          </Row>
          <Row className="mx-3">
            <Col>
              <h2>
                No custom reports found. Please create them using one of the
                builders below.
              </h2>
              <hr></hr>
            </Col>
          </Row>

          <Row className="mx-3">
            <Button
              onClick={() =>
                adminContext.history.push("/admin/custom-reports/builder/pay")
              }
              className="semi-active-button"
            >
              <i className="fa-regular fa-dollar fa-2xl p-3" />
              <h3>Custom Pay Report Builder</h3>
            </Button>
            <Button
              onClick={() =>
                adminContext.history.push(
                  "/admin/custom-reports/builder/employee"
                )
              }
              className="semi-active-button"
            >
              <i className="fa-regular fa-user fa-2xl p-3" />
              <h3>Custom Employee Report Builder</h3>
            </Button>
          </Row>
        </Card>
      </Container>
    );
  }

  return (
    <Container className="mt-4" fluid>
      {!isLoading && form ? (
        <Card className="p-3 m-3">
          <Row className="mx-3">
            <Col>
              <h1 className="days-one">Launch Custom Reports</h1>
            </Col>
          </Row>
          <Row className="mx-3"></Row>
          <Row className="m-3">
            <Col lg="6" md="12">
              {" "}
              <ComboBox
                stretch={true}
                label="Existing Custom Reports"
                name="id"
                comboData={form.existing_reports}
                selectedComboItem={form.selected_report_id}
                setSelectedComboItem={(data) => {
                  setForm({
                    ...form,
                    selected_report_id: adminContext.getKeyByValue(
                      form.existing_reports,
                      data
                    ),
                  });
                }}
              />
            </Col>

            <Col lg="3" md="12">
              <br />
              <Button
                size="lg"
                color="primary"
                onClick={() =>
                  processReport(
                    false,
                    false,
                    true,
                    adminContext,
                    selectedRows,
                    form,
                    setForm,
                    setPdfB64Data,
                    setReactPdfDocument,
                    openModal,
                    form.report_types[form.selected_report_id],
                    setIsLoading
                  )
                }
                className="semi-active-button m-1 w-100"
              >
                Print/Preview (PDF)
              </Button>
            </Col>
            <Col lg="3" md="12">
              <br />

              <Button
                size="lg"
                color="secondary"
                onClick={() => {
                  processReport(
                    false,
                    true,
                    true,
                    adminContext,
                    selectedRows,
                    form,
                    setForm,
                    setPdfB64Data,
                    setReactPdfDocument,
                    openModal,
                    form.report_types[form.selected_report_id],
                    setIsLoading
                  );
                }}
                className="semi-active-button m-1 w-100"
              >
                Export CSV
              </Button>
            </Col>
            <Col lg="6" md="12">
              <DateRangeEdit
                startDateProps={{
                  label: "Start Date",
                  value: form.start_date,
                  onChange: (time) => {
                    setForm({ ...form, start_date: time });
                  },
                }}
                endDateProps={{
                  label: "End Date",
                  value: form.end_date,
                  onChange: (time) => {
                    setForm({ ...form, end_date: time });
                  },
                }}
              />
            </Col>
            <Col lg="3">
              <FontPicker
                fonts={form.fonts}
                fontFamily={form.font_family}
                setFontFamily={(data) => {
                  setForm({ ...form, font_family: data });
                }}
              />
            </Col>
            <Col lg="3">
              <ComboBox
                className="mx-3"
                label="Font Size"
                stretch={true}
                name="font_size"
                selectedComboItem={form.font_size}
                setSelectedComboItem={(data) => {
                  setForm({ ...form, font_size: data });
                }}
                comboData={adminContext.fontSizes()}
              />
            </Col>
          </Row>

          <Row className="m-3">
            <Col lg="3" md="6" sm="12" className="d-flex align-items-center">
              <FormControlLabel
                control={
                  <CheckBox
                    color="primary"
                    className="mx-2"
                    name="show_terminated"
                    checked={!!form.show_terminated}
                    onChange={(e) => {
                      setForm({
                        ...form,
                        show_terminated: e.target.checked,
                      });
                    }}
                  />
                }
                label={"Show Terminated Employees?"}
              />
            </Col>
            {form.use_processed_date_visible && (
              <Col lg="3" md="6" sm="12" className="d-flex align-items-center">
                <FormControlLabel
                  control={
                    <CheckBox
                      color="primary"
                      className="mx-2"
                      name="use_processed_date"
                      checked={!!form.use_processed_date}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          use_processed_date: e.target.checked,
                        });
                      }}
                    />
                  }
                  label={"Use Processed Date?"}
                />
              </Col>
            )}
            {form.show_only_totals_visible && (
              <Col lg="3" md="6" sm="12" className="d-flex align-items-center">
                <FormControlLabel
                  control={
                    <CheckBox
                      color="primary"
                      className="mx-2"
                      name="show_only_totals"
                      checked={!!form.show_only_totals}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          show_only_totals: e.target.checked,
                        });
                      }}
                    />
                  }
                  label={"Show Totals Only?"}
                />
              </Col>
            )}

            {form.group_by_department_visible && (
              <Col lg="3" md="6" sm="12" className="d-flex align-items-center">
                <FormControlLabel
                  control={
                    <CheckBox
                      color="primary"
                      className="mx-2"
                      name="group_by_department"
                      checked={!!form.group_by_department}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          group_by_department: e.target.checked,
                        });
                      }}
                    />
                  }
                  label={"Group By Department?"}
                />
              </Col>
            )}
          </Row>
          <Row className="m-3">
            <Col lg="12" md="12">
              <h3>Employees</h3>
              <ListWidget
                name="employees"
                rows={rows}
                setRows={setRows}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              {pdfB64Data && !adminContext.isNative && (
                <div>
                  <Modal
                    style={customStyle}
                    isOpen={modalIsOpen}
                    contentLabel="Lightning Payroll Report"
                  >
                    <Button
                      color="secondary"
                      onClick={closeModal}
                      className="float-right mb-2"
                    >
                      <FaWindowClose className="mr-3 mb-1" />
                      Close
                    </Button>
                    {reactPdfDocument}
                  </Modal>
                </div>
              )}
            </Col>
            <Col></Col>
          </Row>
        </Card>
      ) : (
        <LoadingSpinner />
      )}
    </Container>
  );
}
export default LaunchCustomReports;
