import { AvField } from "availity-reactstrap-validation";
import { useEffect, useState } from "react";

const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

const MoneyEdit = (props) => {
  const decimalPrecision = clamp(
    parseInt(props.decimalPrecision || 2, 10),
    2,
    6
  );

  // Format value to required decimal precision
  const formatToCurrency = (value) => {
    const numericValue = Number(value);
    return isNaN(numericValue)
      ? "0." + "0".repeat(decimalPrecision)
      : numericValue.toFixed(decimalPrecision);
  };

  const [inputValue, setInputValue] = useState(
    props.amount?._decimal ? formatToCurrency(props.amount._decimal) : ""
  );

  const handleChange = (e) => {
    // Controlled input — keep user edits
    setInputValue(e.target.value);
  };

  const onFocus = (e) => {
    if (!e.target.value || Number(e.target.value) === 0) {
      setInputValue("");
    }
  };

  const onBlur = (e) => {
    const formattedValue = formatToCurrency(e.target.value || "0");
    setInputValue(formattedValue);

    const eventValue = { _decimal: formattedValue };

    props.handleChange?.({
      target: {
        name: e.target.name,
        value: eventValue,
      },
    });

    props.onBlur?.({
      target: {
        name: e.target.name,
        value: eventValue,
      },
    });
  };

  useEffect(() => {
    if (props.amount?._decimal !== undefined) {
      setInputValue(formatToCurrency(props.amount._decimal));
    }
  }, [props.amount?._decimal, decimalPrecision]);

  const negativeStyle = inputValue.startsWith("-") ? "text-danger" : "";

  // Build dynamic regex for decimal validation
  const decimalPattern = `^-?\\d+(\\.\\d{1,${decimalPrecision}})?$`;

  return (
    <AvField
      onChange={handleChange}
      onFocus={onFocus}
      type="text"
      onBlur={onBlur}
      className={negativeStyle}
      value={inputValue}
      name={props.name || "amount"}
      label={props.label ? `${props.label} ($)` : "Amount ($)"}
      validate={{
        required: {
          value: true,
          errorMessage: "Please enter an amount",
        },
        pattern: {
          value: decimalPattern,
          errorMessage: `Invalid amount. Must be in dollars and up to ${decimalPrecision} decimal places.`,
        },
      }}
      disabled={props.disabled}
    />
  );
};

export default MoneyEdit;
