import React, { useEffect, useContext, useState } from "react";
import ComboBox from "../ComboBox";
import dayjs from "dayjs";
import AdminContext from "AdminContext";
import LoadingSpinner from "../LoadingSpinner";

const FinancialYearDateSelector = ({
  setNextEnabled,
  wizardInfo,
  setWizardInfo,
}) => {
  const adminContext = useContext(AdminContext);
  const [comboData, setComboData] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState("");

  /* Use Effects */
  useEffect(() => {
    setLoading(true);
    adminContext.getRequest(
      `${adminContext.constants.BASE_URL}/company/${adminContext.company.id}/available-financial-years`,
      (response) => {
        const financialYears = response.data.financial_years;

        const comboItems = {};
        for (const label of Object.values(financialYears)) {
          comboItems[label] = label;
        }

        setComboData(comboItems);
        setLoading(false);
      }
    );
  }, []);

  // Sets as the most recent financial year
  useEffect(() => {
    const labels = Object.keys(comboData);
    if (labels.length > 0) {
      const lastLabel = labels[0]; 
      handleYearChange(lastLabel);
    }
  }, [comboData]);
 

  /* Functions */
  const handleYearChange = (selectedValue) => {
    const [, dateRange] = selectedValue.split(" - ");
    const [startStr, endStr] = dateRange.split(" to ");

    const startDate = dayjs(startStr, "DD-MM-YYYY").format("YYYY-MM-DD");
    const endDate = dayjs(endStr, "DD-MM-YYYY").format("YYYY-MM-DD");

    setWizardInfo((prev) => ({
      ...prev,
      start_date: startDate,
      end_date: endDate,
    }));

    setSelectedLabel(selectedValue);
  };

  return (
    (loading ? (
      <LoadingSpinner />
    ) : (
    <ComboBox
      label="Select Financial Year"
      comboData={comboData}
      selectedComboItem={selectedLabel}
      setSelectedComboItem={handleYearChange}
      skipSort={true}
    />
    ))
  );
};

export default FinancialYearDateSelector;
